import React from "react"
import Layout from "../layout"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import Contacts from "../components/Contacts"
import FormContact from "../components/FormContact"
import Hero from "../components/Hero"
import Carousel from "../components/Carousel"
import CarouselMobile from "../components/CarouselAbout/mobile"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import { useTranslation, I18nextContext } from 'gatsby-plugin-react-i18next'

const Contactos = ({ location }) => {
  const {t} = useTranslation()
  const activeLanguage = React.useContext(I18nextContext).language
  const breakpoints = useBreakpoint()

  return (
    <Layout 
        location={location}
        footer={t('footer', { returnObjects: true })}
        header={t('header', { returnObjects: true })}
    >
        <SEO
            pathname={location.pathname}
            title={t('keywords', { returnObjects: true })['name']}
            img={t('keywords', { returnObjects: true })['image']}
            imageHeight="628"
            imageWidth="1200"
            lang="pt"
        />

        <Hero
            text={t('introduction', { returnObjects: true })['text']}
            image={t('introduction', { returnObjects: true })['image']}
            color="white"
            hasFilter
            animateIn
            animateSemiOut
            toClick="#carousel-contacts"
        />
      
        {!(activeLanguage === 'br') && (
            !breakpoints.tl ? 
                <Carousel
                    showArrows={false}
                    showCustomArrows={true}
                    showIndicators={false}
                    autoPlay={true}
                    transitionTime={1150}
                    slideTime={6000}
                    delay={1150}
                    animationTime={0.5}
                    id="carousel-contacts"
                    carouselContent={t('carousel', { returnObjects: true })}
                    noText
                />
            : 
                <CarouselMobile 
                    id="carousel-contacts" 
                    carouselContent={t('carousel', { returnObjects: true })} 
                />   
        )}
      
        <Contacts 
            misc={t('misc', { returnObjects: true })} 
            lang={activeLanguage}
            contentMsg={t('header', { returnObjects: true })['msgMarkPhone']}
        />
      
        {!(activeLanguage === 'br') &&
            <FormContact
                id="form-contact"
                info={t('form', { returnObjects: true })}
                lang={activeLanguage}
                location={location}
            />
        }
    </Layout>
  )
}

export default Contactos

export const QueryContactsPT = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["contacts", "form", "footer", "header"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`
